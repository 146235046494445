import { IndexPage, defaultLayoutComponets, ComDetailLayoutComponets, backLayoutComponets, rendingLayoutComponents } from '@/views/layout'
const routes = [
  {
    path: 'PR',
    component: IndexPage,
    children: [
      {
        path: 'PR0100M01',
        name: 'PR0100M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0100M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '혜택', description: '이벤트 혜택에 대한 상세 설명' }
      },
      {
        path: 'PR0101M01',
        name: 'PR0101M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0101M01.vue') }, backLayoutComponets),
        meta: { requiresAuth: false, title: '랭킹 이벤트', description: '랭킹 이벤트' }
      },
      {
        path: 'PR0101M02',
        name: 'PR0101M02',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0101M02.vue') }, backLayoutComponets),
        meta: { requiresAuth: false, title: '랭킹 이벤트', description: '랭킹 이벤트' }
      },
      {
        path: 'PR0102M01',
        name: 'PR0102M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0102M01.vue') }, backLayoutComponets),
        meta: { requiresAuth: false, title: '출석체크 이벤트', description: '출석체크 이벤트' }
      },
      {
        path: 'PR0103M01',
        name: 'PR0103M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0103M01.vue') }, ComDetailLayoutComponets),
        meta: { requiresAuth: false, title: 'SNS팔로우 이벤트', description: 'SNS팔로우 이벤트' }
      },
      {
        path: 'PR0104M01',
        name: 'PR0104M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0104M01.vue') }, backLayoutComponets),
        meta: { requiresAuth: false, title: '리뷰 이벤트', description: '두부분식 리뷰 이벤트' }
      },
      {
        path: 'PR0105M01',
        name: 'PR0105M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0105M01.vue') }, backLayoutComponets),
        meta: { requiresAuth: false, title: '퀴즈 이벤트', description: '초성 퀴즈 이벤트' }
      },
      {
        path: 'PR0106M01',
        name: 'PR0106M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0106M01.vue') }, ComDetailLayoutComponets),
        meta: { requiresAuth: false, title: '핀플 설문 이벤트', description: '핀플 설문 이벤트' }
      },
      {
        path: 'PR0109M01',
        name: 'PR0109M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0109M01.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '리뷰 인증 이벤트', description: '랭킹 이벤트/202502', state: '진행중' }
      },
      {
        path: 'PR0110M02',
        name: 'PR0110M02',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0110M02.vue') }, defaultLayoutComponets),
        meta: { requiresAuth: false, title: '랭킹 이벤트', description: '랭킹 이벤트/202502', state: '진행중' }
      },
      {
        path: 'PR0200M01',
        name: 'PR0200M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0200M01.vue') }, rendingLayoutComponents),
        meta: { requiresAuth: false, title: '두부분식을 소개합니다', description: '두부분식을 소개합니다' }
      },
      {
        path: 'PR0200M02',
        name: 'PR0200M02',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_PR" */ '@/views/PR/PR0200M02.vue') }, backLayoutComponets),
        meta: { requiresAuth: false, title: '두부분식을 소개합니다', description: '두부분식을 소개합니다' }
      }
    ]
  }
]
export default routes
